'use client'

import { type PaletteColor } from '@mui/material'

import { Icon, type IconSizes, type IconName } from '@/icons/icon'
import {
  type HTMLAttributes,
  useState,
  type DetailedHTMLProps,
  type ButtonHTMLAttributes,
  type MouseEventHandler,
} from 'react'

interface ButtonIconBaseProps extends HTMLAttributes<HTMLButtonElement> {
  color: ButtonIconVariants | ButtonIconBaseVariants
  variant?: keyof PaletteColor
  iconActiveVariant?: IconName
  iconVariant: IconName
  iconSize?: IconSizes
  active?: boolean
  canActivate?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
}

interface ButtonProps {
  variant: keyof PaletteColor
}

type ButtonIconBaseVariants = 'light' | 'dark' | 'neutral' | 'warning'

export enum ButtonIconVariants {
  light = 'light',
  dark = 'dark',
  neutral = 'neutral',
}

const getBackgroundColor = (
  variant: keyof PaletteColor,
  color: ButtonIconVariants | ButtonIconBaseVariants,
) => {
  if (variant === 'dark') {
    switch (color) {
      case ButtonIconVariants.light:
        return 'bg-white'
      case ButtonIconVariants.dark:
        return 'bg-dark'

      case ButtonIconVariants.neutral:
        return 'bg-grey-100'
    }
  }
  return 'bg-transparent'
}

const Button = ({
  variant,
  color,
  ...props
}: ButtonProps &
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >) => {
  return (
    <button
      className='h-10 max-h-[40px] w-12 max-w-[40px] cursor-pointer rounded-[4px] border-none p-2'
      style={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        backgroundColor: getBackgroundColor(variant, color as any),
      }}
      {...props}
    />
  )
}

export const ButtonIcon = ({
  variant = 'main',
  color,
  iconVariant = 'love-it-regular',
  iconActiveVariant,
  iconSize = 'm',
  active: propsActive,
  canActivate = false,
  onClick,
  ...props
}: ButtonIconBaseProps) => {
  const [active, setActive] = useState(false)

  const getIconName = () => {
    const isActive = propsActive ?? active
    if (canActivate && isActive && iconActiveVariant != null) {
      return iconActiveVariant
    }
    return iconVariant
  }

  const iconStyles = {
    opacity: props.disabled ? 0.4 : 1,
  }
  if (iconVariant === 'subtract-regular' || iconVariant === 'add-regular') {
    Object.assign(iconStyles, {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    })
  }

  return (
    <Button
      variant={variant}
      onClick={(event) => {
        if (onClick) onClick(event)
        if (propsActive === undefined) setActive(!active)
      }}
      {...props}
      style={iconStyles}
    >
      <Icon
        data-testid={getIconName()}
        name={getIconName()}
        size={iconSize}
        color={color}
        variant={variant}
      />
    </Button>
  )
}
