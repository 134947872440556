/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/namespace */
import * as Sentry from '@sentry/nextjs'

import {
  type Cart,
  DeliveryMethod,
  type Product,
} from '@/lib/generated/graphql'
import { type CartLevels, type RootNode } from '@/lib/generated/hypertune'
import { addToCartClient, upsertNewCartClient } from '@/lib/data/cart-queries'

import {
  LasooCartAction,
  type LasooCartInput,
} from '@/lib/generated/platform-graphql'
import { toast } from 'react-toastify'
import { type UserCart } from '../cart-types'
import { mapUserCartToInput } from '../cart-actions/map-platform-cart-to-input'

const upsertNewCart = async (
  product: Product,
  cartLevel: CartLevels,
  quantity: number,
  userCart?: UserCart,
  variantId?: string | null,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const cartToUpdate: LasooCartInput = mapUserCartToInput(userCart)

  if (cartLevel === 'OFF') {
    return
  }

  try {
    const existingItemIndex =
      cartToUpdate.items?.findIndex(
        (item) =>
          item?.advertId === product?.product_advertLookUp &&
          item?.variantId === variantId,
      ) ?? -1
    if (
      existingItemIndex > -1 &&
      cartToUpdate.items[existingItemIndex] != null
    ) {
      cartToUpdate.items[existingItemIndex].quantity += quantity
    } else {
      cartToUpdate.items.push({
        productId: Number.parseInt(product?.product_id ?? ''),
        retailerId: product?.product_retailerId as string,
        advertId: product?.product_advertLookUp as string,
        variantId: variantId as string,
        quantity,
      })
    }
    const cartResponse = await upsertNewCartClient(
      cartToUpdate,
      LasooCartAction.AddToCart,
    )
    if (cartResponse == null || cartResponse === false) {
      Sentry.captureException(new Error('Error upserting new cart'))
      return
    }

    return cartResponse
  } catch (error) {
    Sentry.captureException(error)
    if (cartLevel === 'New_Cart_Primary') {
      throw error
    }
  }
}

const updateOldCart = async (
  product: Product,
  quantity: number,
  cartLevel: CartLevels,
  cartInternal?: Cart,
  variantId?: string | null,
) => {
  try {
    const existingQuantity =
      cartInternal?.items?.find(
        (item) =>
          item?.advert?.id === product?.product_advertLookUp &&
          item?.variant?.id === variantId,
      )?.quantity ?? 0

    const cart = await addToCartClient({
      productId: Number.parseInt(product?.product_id as string),
      variantId: variantId as string,
      advertId: product?.product_advertLookUp as string,
      quantity: existingQuantity + quantity,
      deliveryMethod: DeliveryMethod.Deliver,
      cartId: cartInternal?.id ?? undefined,
    })
    if (cart != null && cart !== false) {
      return cart
    }
  } catch (error) {
    Sentry.captureException(error)
    if (cartLevel !== 'New_Cart_Primary') {
      throw error
    }
  }
}

const showErrorToast = () => {
  toast('Error adding cart item', {
    className: 'bg-error text-white',
    position: 'top-center',
  })
}

const showSuccessToast = () => {
  toast(<div className='text-white'>Added to Cart</div>, {
    className: 'bg-success  text-white',
    position: 'bottom-center',
  })
}

export const addToCart = async (
  product: Product,
  cartLevel: CartLevels,
  setCart: (cart?: Cart, newCart?: UserCart) => void,
  hypertune: RootNode,
  userCart?: UserCart,
  cartInternal?: Cart,
  variantId?: string | null,
  quantity?: number,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  // eslint-disable-next-line unicorn/prefer-default-parameters
  const itemQuantity = quantity ?? 1

  if (itemQuantity < 1) {
    showErrorToast()
    return
  }

  const newCartOperation =
    cartLevel === 'OFF'
      ? Promise.resolve(null)
      : upsertNewCart(product, cartLevel, itemQuantity, userCart, variantId)

  const oldCartOperation =
    cartLevel === 'New_Cart_Primary'
      ? Promise.resolve(null)
      : updateOldCart(product, itemQuantity, cartLevel, cartInternal, variantId)

  const [newCart, oldCart] = await Promise.all([
    newCartOperation,
    oldCartOperation,
  ])

  if (cartLevel === 'New_Cart_Primary') {
    if (newCart?.__typename === 'LasooCart') {
      setCart(undefined, newCart)
      hypertune.addToCart()
      showSuccessToast()
    } else {
      showErrorToast()
    }
  } else {
    if (oldCart?.__typename === 'cart') {
      setCart(
        oldCart,
        newCart?.__typename === 'LasooCart' ? newCart : undefined,
      )
      hypertune.addToCart()
      showSuccessToast()
    } else {
      showErrorToast()
    }
  }
}
