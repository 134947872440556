/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable unicorn/no-useless-undefined */
/* eslint-disable @typescript-eslint/no-unused-vars */
'use client'

import { type Product, type Cart, type AoStock } from '@/lib/generated/graphql'
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import { useSessionStorage } from 'react-use'
import { mapCartToUi } from './cart-actions/map-cart-to-ui'
import {
  type CartLine,
  type UICart,
  type UserCart,
  type NewCartWithPayment,
} from './cart-types'
import { CartItemStatus } from '@/lib/generated/graphql'
import { usePathname, useRouter } from 'next/navigation'
import { routeCalculatorRelative } from '@/lib/route-calculator'
import { useUserActionError } from '../utility/user-error/user-action-error-context'
import { getPostcodeClient } from '@/lib/data/postcode-client'
import { upsertNewCartClient } from '@/lib/data/cart-queries'

import {
  deleteBasketClient,
  setBasketClient,
  setPlatformBasketClient,
} from '@/lib/data/basket-cookie-client'
import { useHypertune } from '@/lib/generated/hypertune.react'
import { mapUserCartToUi } from './cart-actions/map-platform-cart-to-ui'
import { addToCart } from './cart-mutations/add-to-cart'
import { updateCartItemQuantity } from './cart-mutations/update-cart'
import {
  LasooCartAction,
  type LasooCartCustomerDetails,
} from '@/lib/generated/platform-graphql'
import { mapUserCartToInput } from './cart-actions/map-platform-cart-to-input'
import { updateCartCoupon } from './cart-mutations/update-cart-coupon'

import { auditCartProperties } from './utilities/ui-cart-audit'
import { CartFillerServer } from './cart-filler-server'
import { Hub } from '@aws-amplify/core'
import { getCart, getPlatformCart } from '@/lib/data/get-cart-queries'

interface CartContextType {
  cart?: Cart
  uiCart?: UICart
  setCart: (cart?: Cart, newCart?: UserCart) => void
  couponCode?: string | null
  setCouponCode: (couponCode: string | null) => Promise<boolean | undefined>
  cartCount?: number
  couponError?: boolean
  setCartCount: (cartCount: number) => void
  cartValid?: boolean
  cartLoading: boolean
  userCart?: UserCart
  clearCart: () => void
  updateCartQuantity: (
    productId: number,
    advertId: string,
    lineId: string,
    variantId: string,
    quantity: number,
  ) => Promise<void>
  upsertToCart: (
    product: Product,
    variantId?: string | null,
    quantity?: number,
    aoStockCodes?: AoStock[],
  ) => Promise<void>
  lockUpsertCart: (lock: boolean) => void
  addCartItemValidator: (validator: () => boolean) => void
  validateCartItems: () => boolean
  setNewCart: (newCart: UserCart) => void
  setCustomerData: (customerData: LasooCartCustomerDetails) => void
}

export const CartContextSSR = createContext<CartContextType>({
  cartCount: 0,
  cartLoading: false,
  clearCart: () => {
    throw new Error('clearCart not implemented')
  },
  setCart: () => {
    throw new Error('setCart not implemented')
  },
  setCartCount: () => {
    throw new Error('setCartCount not implemented')
  },
  updateCartQuantity: (
    productId: number,
    advertId: string,
    lineId: string,
    variantId: string,
    quantity: number,
  ) => {
    throw new Error('updateCartQuantity not implemented')
  },
  upsertToCart: (
    product: Product,
    variantId?: string | null,
    quantity?: number,
    aoStockCodes?: AoStock[],
  ) => {
    throw new Error('upsertToCart not implemented')
  },
  lockUpsertCart: (lock: boolean) =>
    new Error('lockUpsertCart not implemented'),
  setCouponCode: () => {
    throw new Error('setCouponCode not implemented')
  },
  addCartItemValidator: () => {},
  validateCartItems: () => {
    return false
  },
  setNewCart: (newCart: UserCart) => {
    throw new Error('setNewCart not implemented')
  },
  setCustomerData: () => {
    throw new Error('setCustomerData not implemented')
  },
})

export const useCart = () => useContext(CartContextSSR)

const getCartCount = (cart?: Cart) => {
  return cart?.items?.reduce((acc, item) => acc + (item?.quantity ?? 0), 0) ?? 0
}

export const CartProviderSSR = ({
  children,
  cart,
  newCart,
}: PropsWithChildren<{ cart?: Cart; newCart?: NewCartWithPayment }>) => {
  const path = usePathname()
  const router = useRouter()
  const hypertune = useHypertune()
  const { setUserActionError } = useUserActionError()

  const [upsertCartLocked, lockUpsertCart] = useSessionStorage(
    'upsertCartLocked',
    false,
  )

  const [couponError, setCouponError] = useState<boolean | undefined>(undefined)

  const [cartInternal, setCartInternal] = useState<Cart | undefined>(cart)
  const [userCart, setUserCart] = useState<UserCart | undefined>(newCart)
  const cartLevel = hypertune.cartLevel({ fallback: 'OFF' })
  const isNewCart = cartLevel === 'New_Cart_Primary'

  const [couponCode, setCouponCodeInternal] = useState<string | null>(() => {
    return (isNewCart ? newCart?.coupon : cart?.couponCode) ?? null
  })

  const [uiCart, setUICart] = useState<UICart | undefined>(() => {
    return isNewCart
      ? newCart == null
        ? undefined
        : mapUserCartToUi(newCart, getPostcodeClient())
      : mapCartToUi(cart, getPostcodeClient())
  })

  const [uiAltCart, setUIAltCart] = useState<UICart | undefined>(() => {
    if (newCart != null) {
      return mapUserCartToUi(newCart, getPostcodeClient())
    }
  })

  const [cartValid, setCartValid] = useState<boolean>(true)
  const [cartLoading, setCartLoading] = useState<boolean>(false)
  const [cartCount, setCartCount] = useState<number | undefined>(() => {
    return isNewCart ? (newCart?.totalQuantity ?? 0) : getCartCount(cart)
  })

  const [auditCart, setAuditCart] = useState<boolean>(true)

  const [cartItemValidators, addCartItemValidators] = useState<
    Array<() => boolean>
  >([])

  const clearCart = useCallback(() => {
    setCartInternal(undefined)
    setUserCart(undefined)
    setUICart(undefined)
    setUIAltCart(undefined)
    setCartCount(0)
    setCartLoading(false)
    deleteBasketClient()
  }, [])

  const setCart = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (cart?: Cart, newCart?: UserCart) => {
      const cartCount =
        (isNewCart ? newCart?.totalQuantity : getCartCount(cart)) ?? 0

      if (cartLevel !== 'OFF') {
        setPlatformBasketClient(newCart?.cartId as string)
      }
      if (!isNewCart) {
        setBasketClient(cart?.id as string)
      }

      setCartCount(cartCount)
      const postcode = getPostcodeClient()

      const mappedUICartFromUserCart: UICart | undefined =
        newCart == null ? undefined : mapUserCartToUi(newCart, postcode)
      const mappedUICartFromCartInternal: UICart | undefined =
        cart == null ? undefined : mapCartToUi(cart, postcode)

      if (!isNewCart && cart != null) {
        setCartInternal(cart)
        if (isNewCart) {
          setUIAltCart(mappedUICartFromCartInternal)
        } else {
          setCouponCodeInternal(cart.couponCode ?? null)
          setUICart(mappedUICartFromCartInternal)
        }
      }
      if (newCart != null) {
        setUserCart(newCart)
        if (isNewCart) {
          setCouponCodeInternal(newCart.coupon ?? null)
          setUICart(mappedUICartFromUserCart)
        } else {
          setUIAltCart(mappedUICartFromUserCart)
        }
      }

      auditCartProperties(
        cartLevel,
        auditCart,
        setAuditCart,
        cart?.createdAt,
        newCart?.createdAt,
        mappedUICartFromCartInternal,
        mappedUICartFromUserCart,
        path,
      )

      setCartLoading(false)
    },
    [cartLevel, isNewCart, auditCart],
  )

  useEffect(() => {
    Hub.listen('auth', async (data) => {
      // eslint-disable-next-line sonarjs/no-small-switch
      switch (data.payload.event) {
        case 'signedIn': {
          let platformCart: UserCart | undefined
          let cart: Cart | undefined
          if (cartLevel !== 'OFF') {
            platformCart = await getPlatformCart()
          }
          if (cartLevel !== 'New_Cart_Primary') {
            const res = await getCart()
            cart = res === false ? undefined : res
          }
          const cartSet = cart
          setCart(cart, platformCart)
          break
        }
        case 'signedOut':
          clearCart()
          break
      }
    })
  }, [cartLevel, clearCart, router, setCart])

  const setCustomerData = useCallback(
    async (customerDetails: LasooCartCustomerDetails) => {
      if (upsertCartLocked) {
        return undefined
      }
      if (cartLevel !== 'OFF') {
        const newCart = {
          ...userCart,
          customerDetails,
        } as UserCart
        const cartInput = mapUserCartToInput(newCart)
        const updatedCart = await upsertNewCartClient(
          cartInput,
          LasooCartAction.UpdateCustomerDetails,
        )
        if (updatedCart !== false && updatedCart?.__typename === 'LasooCart') {
          setCart(cartInternal, updatedCart)
        }
      }
    },
    [cartInternal, cartLevel, setCart, userCart],
  )

  const setCouponCode = useCallback(
    async (coupon: string | null) => {
      if (upsertCartLocked) {
        return undefined
      }
      return await updateCartCoupon(
        cartLevel,
        setCart,
        coupon,
        userCart,
        cartInternal,
      )
    },
    [cartInternal, cartLevel, setCart, userCart],
  )

  const isCartValidInternal = useCallback(
    (uiCart: UICart, triggerSetUserAction: boolean = true): boolean => {
      const errorLines: CartLine[] = []
      if (uiCart?.lineGroups === undefined) return false
      for (const retailers of uiCart.lineGroups) {
        if (retailers.lines === undefined) return false
        for (const cartLine of retailers.lines) {
          if (cartLine.status !== CartItemStatus.Valid) {
            errorLines.push(cartLine)
          }
        }
      }
      if (errorLines.length === 0) return true

      const errorMessage = `${errorLines.length} item(s) from your cart are no longer available. Please check cart and try again.`

      if (triggerSetUserAction) {
        setUserActionError({
          error_title: 'Invalid items on Cart',
          error_message: errorMessage,
          buttonLabel: 'Check Cart',
          buttonAction: () => {
            router.push(routeCalculatorRelative.cart)
            router.refresh()
          },
        })
      }

      return false
    },
    [router, setUserActionError],
  )

  useEffect(() => {
    setCartValid(
      isCartValidInternal(
        uiCart as UICart,
        path !== routeCalculatorRelative.cart,
      ),
    )
  }, [uiCart, path, isCartValidInternal])

  const updateCartQuantity = useCallback(
    async (
      productId: number,
      advertId: string,
      lineId: string,
      variantId: string,
      quantity: number,
    ) => {
      if (upsertCartLocked) {
        return undefined
      }
      setCartLoading(true)

      await updateCartItemQuantity(
        productId,
        advertId,
        variantId,
        quantity,
        setCart,
        cartLevel,
        cartInternal,
        userCart,
      )

      setCartLoading(false)
    },
    [cartInternal, cartLevel, setCart, userCart],
  )

  const upsertToCart = useCallback(
    async (product: Product, variantId?: string | null, quantity?: number) => {
      setCartLoading(true)

      await addToCart(
        product,
        cartLevel,
        setCart,
        hypertune,
        userCart,
        cartInternal,
        variantId,
        quantity,
      )
      setCartLoading(false)
    },
    [cartInternal, cartLevel, userCart, setCart],
  )

  const addCartItemValidator = useCallback((validator: () => boolean) => {
    addCartItemValidators((prev) => [...prev, validator])
  }, [])

  const validateCartItems = useCallback(() => {
    let cartValid = true
    for (const validator of cartItemValidators) {
      cartValid = cartValid && validator()
    }
    return cartValid
  }, [cartItemValidators])

  const value = useMemo(
    () => ({
      cart: cartInternal,
      setCart,
      cartCount,
      setCartCount,
      upsertToCart,
      cartLoading,
      updateCartQuantity,
      cartValid,
      couponError,
      couponCode,
      setCouponCode,
      uiCart,
      addCartItemValidator,
      validateCartItems,
      clearCart,
      setNewCart: setUserCart,
      userCart,
      setCustomerData,
      lockUpsertCart,
    }),
    [
      cartInternal,
      setCart,
      cartCount,
      upsertToCart,
      cartLoading,
      updateCartQuantity,
      cartValid,
      couponError,
      couponCode,
      setCouponCode,
      uiCart,
      addCartItemValidator,
      validateCartItems,
      clearCart,
      setUserCart,
      userCart,
      setCustomerData,
      lockUpsertCart,
    ],
  )

  return (
    <CartContextSSR.Provider value={value}>{children}</CartContextSSR.Provider>
  )
}
