import {
  type AdvertVariant,
  type Product,
  type Maybe,
  type AdvertVariantList,
} from '@/lib/generated/graphql'
import { type Variant } from '@/lib/generated/platform-graphql'

export const priceFormat = (price?: Maybe<number>) => {
  const AUCurrencyFormat = Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return price != null && price >= 0 ? AUCurrencyFormat.format(price) : ''
}

// TODO: test me
export const checkPrice = (price: number | undefined | null) => {
  if (price === undefined || price === null || price === 0) {
    return 0
  }
  return price / 100
}
export const getShippingCost = (shippingCostCents: number | undefined) => {
  if (shippingCostCents !== undefined && shippingCostCents > 0) {
    return priceFormat(shippingCostCents / 100)
  }

  return 'Shipping Free'
}

// TODO: what is shippingHint, type, do we need it? does shippingEstimationMessage already include it.
export const getDeliveryHint = (shippingHint: number | string) => {
  if (typeof shippingHint === 'string' && shippingHint.length > 0) {
    return shippingHint
  }
  if (
    shippingHint === undefined ||
    shippingHint === null ||
    shippingHint === 0
  ) {
    return
  }
  if (typeof shippingHint === 'number' && shippingHint > 0) {
    return `Free shipping on orders over ${priceFormat(shippingHint)}`
  }
}

export const formatProductPrice = (price?: Maybe<number> | undefined) =>
  (price ?? 0) > 0 ? `${priceFormat(price)}` : ''

export const formatProductPriceCents = (price: number) =>
  priceFormat(price / 100)

export type PriceRecord =
  | DiscountPriceProps
  | Record<string, DiscountPriceProps>

export interface DiscountPriceProps {
  productPrice?: Maybe<number> | undefined
  productDiscountPrice?: Maybe<number> | undefined
  variantPrice?: Maybe<number> | undefined
  variantDiscountPrice?: Maybe<number> | undefined
}

const forceFloat = (
  price: string | number | null | undefined,
): number | undefined => {
  const parsedPrice =
    typeof price === 'number' ? price : Number.parseFloat(price ?? '')
  return Number.isNaN(parsedPrice) ? undefined : parsedPrice
}

interface EditablePrices {
  editablePrice?: string | number | null | undefined
  editableSalePrice?: string | number | null | undefined
}

export const getPricesFromCartLine = (
  advert: EditablePrices | null | undefined,
  variant: EditablePrices | null | undefined,
): DiscountPriceProps => ({
  productPrice: forceFloat(advert?.editablePrice),
  productDiscountPrice: forceFloat(advert?.editableSalePrice),
  variantPrice: forceFloat(variant?.editablePrice),
  variantDiscountPrice: forceFloat(variant?.editableSalePrice),
})

// TODO: test me
export const getPricesFromVariants = (
  product: Product | undefined,
  variantData?: AdvertVariantList | undefined,
): Record<string, DiscountPriceProps> | DiscountPriceProps => {
  return variantData?.__typename === 'advertVariantList' &&
    variantData.items != null &&
    variantData.items?.length > 0
    ? variantData.items?.reduce<Record<string, DiscountPriceProps>>(
        (memo, variant) => {
          return {
            ...memo,
            [`${variant?.id}`]: {
              productPrice: forceFloat(variantData?.editablePrice),
              productDiscountPrice: forceFloat(variantData?.editableSalePrice),
            },
          }
        },
        {},
      )
    : {
        productPrice: product?.product_price,
        productDiscountPrice: product?.product_salePrice,
      }
}

// TODO: test me
export const getPricesFromVariant = (
  variantData: PriceRecord,
  selectedVariant?: AdvertVariant | null | undefined,
): DiscountPriceProps => {
  if ('variantPrice' in variantData || selectedVariant == null) {
    return variantData
  }
  return {
    ...(variantData as Record<string, DiscountPriceProps>)[
      selectedVariant?.id as string
    ],
    variantPrice: selectedVariant?.editablePrice,
    variantDiscountPrice: selectedVariant?.editableSalePrice,
  }
}

const getDiscountedPrice = ({
  productPrice,
  productDiscountPrice,
  variantPrice,
  variantDiscountPrice,
}: DiscountPriceProps): number => {
  // The current variant has a sale price: use it
  // Example: the basic and premium versions of a product are both on sale at different prices
  if (variantDiscountPrice !== undefined && variantDiscountPrice !== null) {
    return variantDiscountPrice
  }
  // The current variant is not on sale, and has its own price: use that price
  // Example: a ring is on sale in normal sizes but the extra-large size is excluded from the sale
  if (variantPrice !== undefined && variantPrice !== null) {
    return variantPrice
  }
  // The variant has no specific price and inherits the advert's prices
  // The product is on sale: use the sale price
  // Example: a product is on sale and available in multiple colours all at the same price
  if (productDiscountPrice !== undefined && productDiscountPrice !== null) {
    return productDiscountPrice
  }
  // Nothing special, there's only one usable price
  return productPrice ?? 0
}
export const getDiscountPriceForDisplay = (prices: DiscountPriceProps) =>
  getDiscountedPrice(prices)

export const getFormattedDiscountPrice = (prices: DiscountPriceProps) =>
  formatProductPrice(getDiscountPriceForDisplay(prices))

export const getProductSalePriceInCents = (
  product: Product,
  variant?: Variant,
) => {
  if (variant !== undefined) {
    return variant.salePriceInCents
  }

  return (product.product_salePrice ?? 0) * 100
}

export const getProductPriceInCents = (product: Product, variant?: Variant) => {
  if (variant !== undefined) {
    return variant.priceInCents
  }

  return (product.product_price ?? 0) * 100
}

export const getProductSavings = (
  product: Product,
  variant?: Variant,
): number => {
  if (variant !== undefined) {
    return variant.priceInCents - variant.salePriceInCents
  }

  if (product.product_price != null && product.product_salePrice != null) {
    return product.product_price - product.product_salePrice
  }

  return 0
}

const getOriginalPrice = ({
  productPrice,
  variantPrice,
}: DiscountPriceProps): number => {
  if (variantPrice !== undefined && variantPrice !== null) {
    return variantPrice
  }
  return productPrice ?? 0
}

export const getOriginalPriceForDisplay = ({
  ...prices
}: DiscountPriceProps) => {
  const salePrice = getDiscountedPrice(prices)
  const originalPrice = getOriginalPrice(prices)
  // Ignore the base price if it's not actually a discount
  return salePrice > 0 && salePrice < originalPrice ? originalPrice : 0
}

export const getFormattedOriginalPrice = (prices: DiscountPriceProps) =>
  formatProductPrice(getOriginalPriceForDisplay(prices))

const getSavings = (prices: DiscountPriceProps): number => {
  const salePrice = getDiscountedPrice({ ...prices })
  const originalPrice = getOriginalPrice({ ...prices })
  const savings = originalPrice - salePrice
  return savings > 0 && salePrice > 0 ? savings : 0
}

export const getFormattedSavings = (prices: DiscountPriceProps) =>
  formatProductPrice(getSavings(prices))
