/* eslint-disable sonarjs/cognitive-complexity */
import { type CartGroup, type UICart } from '@/components/cart/cart-types'
import {
  CartItemStatus,
  DeliveryMethod,
  RetailerDeliveryMethod,
  type Cart,
  type CartSeller,
  type Maybe,
} from '@/lib/generated/graphql'
import { getMaxBuyableQuantity } from '@/utils/functions/cart/get-max-buyable-quantity'
import {
  getDiscountPriceForDisplay,
  getOriginalPriceForDisplay,
  getPricesFromCartLine,
} from '@/utils/functions/price-format'

const getLineGroups = (
  cart?: Cart,
  customerPostcode?: Maybe<string> | undefined,
) => {
  const cartGroups = cart?.items?.reduce<CartGroup[]>((acc, cartItem) => {
    const cartGroup = acc.find((group) => group.id === cartItem?.seller?.id)
    const prices = getPricesFromCartLine(cartItem?.advert, cartItem?.variant)

    if (cartGroup) {
      cartGroup.lines.push({
        id: cartItem?.id as string,
        productId: Number.parseInt(cartItem?.product?.product_id ?? '0'),
        quantity: cartItem?.quantity as number,
        salePrice: getDiscountPriceForDisplay(prices),
        price: getOriginalPriceForDisplay(prices),
        productName: cartItem?.advert?.title as string,
        productImageSrc: cartItem?.product?.product_imageUrl as string,
        advertId: cartItem?.advert?.id as string,
        variantId: cartItem?.variant?.id as string,
        available: cartItem?.variant?.available ?? false,
        maxQuantity: getMaxBuyableQuantity(
          cartItem?.variant,
          cartItem?.advert,
          cartItem?.product,
          customerPostcode,
        ),
        status: cartItem?.status ?? CartItemStatus.ProductNotFound,
        variantLabel: cartItem?.variant?.label as string,
        categoryLevel1: cartItem?.product?.product_categorylevel1 ?? '',
      })
    } else {
      acc.push({
        id: cartItem?.seller?.id as string,
        isCart: false,
        lines: [
          {
            id: cartItem?.id as string,
            productId: Number.parseInt(cartItem?.product?.product_id ?? '0'),
            quantity: cartItem?.quantity as number,
            salePrice: getDiscountPriceForDisplay(prices),
            price: getOriginalPriceForDisplay(prices),
            productName: cartItem?.advert?.title as string,
            productImageSrc: cartItem?.product?.product_imageUrl as string,
            advertId: cartItem?.advert?.id as string,
            variantId: cartItem?.variant?.id as string,
            available: cartItem?.variant?.available ?? false,
            maxQuantity: getMaxBuyableQuantity(
              cartItem?.variant,
              cartItem?.advert,
              cartItem?.product,
              customerPostcode,
            ),
            status: cartItem?.status ?? CartItemStatus.ProductNotFound,
            variantLabel: cartItem?.variant?.label as string,
            categoryLevel1: cartItem?.product?.product_categorylevel1 ?? '',
          },
        ],
        retailerName: cartItem?.product?.product_retailer
          ?.retailer_displayName as string,
        retailerLogoSrc: cartItem?.product?.product_retailer
          ?.retailer_logoUrl as string,
        retailerLogoBgColor: cartItem?.product?.product_retailer
          ?.retailer_colour as string,
        shippingEstimationMessage: cartItem?.product?.product_retailer
          ?.retailer_shippingEstimationMessage as string,
      })
    }
    return acc
  }, [])

  if (cartGroups)
    for (const cartGroup of cartGroups) {
      const cartSeller = cart?.cartSellers?.find(
        (seller) => seller?.id === cartGroup.id,
      )

      if (cartSeller) {
        const {
          baseDomesticShippingCost,
          businessName,
          domesticShippingFreeThreshold,
          cartItemDeliveryMethodGroup,
        } = cartSeller

        const deliveryMethodGroup = cartItemDeliveryMethodGroup?.find(
          (group) => group?.deliveryMethod === DeliveryMethod.Deliver,
        )

        const groupShippingDetails = {
          baseDomesticShippingCost,
          businessName,
          shippingHint: domesticShippingFreeThreshold,
          cartItemDeliveryMethodGroup: deliveryMethodGroup,
          shippingEstimationMessage: deliveryMethodGroup?.shippingDescription,
        }

        cartGroup.groupShippingDetails = groupShippingDetails
      } else {
        cartGroup.groupShippingDetails = {
          baseDomesticShippingCost: undefined,
          businessName: undefined,
          shippingHint: undefined,
          cartItemDeliveryMethodGroup: undefined,
        }
      }
    }

  return cartGroups ?? []
}

const getRetailerDeliveryMethod: (
  cart?: Cart,
) => RetailerDeliveryMethod | null = (cart) => {
  const deliveryMethods =
    cart?.items?.map(
      (item) => item?.product?.product_retailer?.retailer_deliveryMethod,
    ) ?? []

  if (deliveryMethods.includes(RetailerDeliveryMethod.SameDay_2024)) {
    return RetailerDeliveryMethod.SameDay_2024
  }

  if (deliveryMethods.includes(RetailerDeliveryMethod.SameDay_2023)) {
    return RetailerDeliveryMethod.SameDay_2023
  }

  return RetailerDeliveryMethod.Standard
}

export const mapCartToUi = (
  cart?: Cart,
  customerPostcode?: Maybe<string> | undefined,
) => {
  const requireOverAgeCheck = getAgeCheckFlag(cart)

  const innerCart: UICart = {
    id: cart?.id,
    lineGroups: getLineGroups(cart, customerPostcode),
    totalQuantity: cart?.buyableQuantity ?? 0,
    total: cart?.totalCents ?? undefined,
    totalDiscount: cart?.discountCents ?? undefined,
    subtotal: cart?.subtotalCents ?? undefined,
    shippingCost: cart?.shippingCostCents ?? undefined,
    couponCode: cart?.couponCode ?? undefined,
    deliveryFees: getDeliveryFees(cart?.cartSellers ?? undefined),
    requireOverAgeCheck,
    itemsValid:
      cart?.items?.every(
        (item) => item?.status === CartItemStatus.Valid ?? false,
      ) ?? true,
    customerDetails: {
      firstName: cart?.billingFirstName ?? undefined,
      surname: cart?.billingSurname ?? undefined,
      emailAddress: cart?.billingEmailAddress ?? undefined,
      billingPhone: cart?.billingPhone ?? undefined,
      address: { ...cart?.address },
      billingAddress: {
        ...cart?.billingAddress,
      },
      cartId: cart?.id ?? undefined,
    },
    retailerDeliveryMethod: requireOverAgeCheck
      ? getRetailerDeliveryMethod(cart)
      : null,
  }

  return innerCart
}

const getAgeCheckFlag: (cart?: Cart) => boolean = (cart) =>
  cart?.items?.some(
    (item) =>
      item?.product?.product_retailer?.retailer_requireOverAgeCheck === true,
  ) ?? false

export const getDeliveryFees = (
  sellersGroups: Array<Maybe<CartSeller>> | undefined,
) => {
  if (!sellersGroups) {
    return
  }

  return sellersGroups.map((seller: Maybe<CartSeller>) => {
    const deliveryMethodGroup = seller?.cartItemDeliveryMethodGroup?.find(
      (group) => group?.deliveryMethod === DeliveryMethod.Deliver,
    )

    return {
      retailer: seller?.businessName,
      deliveryFee: deliveryMethodGroup?.shippingCostCents,
    }
  })
}
