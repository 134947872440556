/* eslint-disable import/namespace */
import * as Sentry from '@sentry/nextjs'

import { type Cart } from '@/lib/generated/graphql'
import { type CartLevels } from '@/lib/generated/hypertune'
import { type UserCart } from '../cart-types'
import { updateCartCouponServer } from '@/lib/data/cart/update-cart-coupon'
import { cartToMetaDataCoupon } from '@/components/checkout/checkout-functions'
import { isNullOrEmpty } from '@/utils/string-extensions'
import { getCart } from '@/lib/data/get-cart-queries'
import { getBasketClient } from '@/lib/data/basket-cookie-client'
import {
  LasooCartAction,
  type LasooCartInput,
} from '@/lib/generated/platform-graphql'
import { mapUserCartToInput } from '../cart-actions/map-platform-cart-to-input'
import { upsertNewCartClient } from '@/lib/data/cart-queries'
import { Zoom, toast } from 'react-toastify'

const updateCouponForUserCart = async (
  cartLevel: CartLevels,
  couponCode: string | null,
  userCart?: UserCart,
): Promise<{ cart?: UserCart; success: boolean }> => {
  if (cartLevel === 'OFF') {
    return { cart: undefined, success: true }
  }
  try {
    const cartToUpdate: LasooCartInput = mapUserCartToInput(userCart)
    cartToUpdate.coupon = couponCode

    const cart = await upsertNewCartClient(
      cartToUpdate,
      LasooCartAction.AddCoupon,
    )
    if (cart !== false && cart?.__typename === 'LasooCart') {
      return { cart, success: true }
    }
    Sentry.captureException(new Error('Error upserting user cart'))
  } catch (error) {
    Sentry.captureException(error)
    if (cartLevel === 'New_Cart_Primary') {
      throw error
    }
  }
  return { cart: undefined, success: false }
}

const updateCouponForCartInternal = async (
  cartLevel: CartLevels,
  couponCode: string | null,
  cartInternal?: Cart,
): Promise<{ cart?: Cart; success: boolean }> => {
  try {
    const cart = await updateCartCouponServer({
      cartMetadata: cartToMetaDataCoupon(cartInternal as Cart, couponCode),
    })
    if (cart !== false && cart?.__typename === 'cart') {
      return { cart, success: true }
    }
    if (isNullOrEmpty(couponCode)) {
      const existingCart = await getCart({
        cartId: cartInternal?.id ?? getBasketClient(),
      })
      if (existingCart !== false && existingCart?.__typename === 'cart') {
        return { cart: existingCart, success: true }
      }
    }
  } catch (error) {
    Sentry.captureException(error)
    if (cartLevel !== 'New_Cart_Primary') {
      throw error
    }
  }
  return { cart: undefined, success: false }
}

export const updateCartCoupon = async (
  cartLevel: CartLevels,
  setCart: (cart?: Cart, newCart?: UserCart) => void,
  couponCode: string | null,
  userCart?: UserCart,
  cartInternal?: Cart,
) => {
  // TODO: revalidate this logic for when we use new cart
  if (
    (cartLevel === 'New_Cart_Primary' && userCart == null) ||
    (cartLevel !== 'New_Cart_Primary' && cartInternal == null)
  ) {
    toast(<div className='text-white'>Cart is empty</div>, {
      className: 'bg-red-500 text-white',
      position: 'bottom-center',
      transition: Zoom,
    })
    return true
  }

  const userCartOperation =
    cartLevel === 'OFF'
      ? Promise.resolve(null)
      : updateCouponForUserCart(cartLevel, couponCode, userCart)
  const cartInternalOperation =
    cartLevel === 'New_Cart_Primary'
      ? Promise.resolve(null)
      : updateCouponForCartInternal(cartLevel, couponCode, cartInternal)

  const [userCartResult, internalCartResult] = await Promise.all([
    userCartOperation,
    cartInternalOperation,
  ])

  if (
    cartLevel === 'New_Cart_Primary' &&
    userCartResult?.cart?.__typename === 'LasooCart'
  ) {
    setCart(undefined, userCartResult.cart)
  } else if (internalCartResult?.cart?.__typename === 'cart') {
    setCart(
      internalCartResult.cart,
      userCartResult?.cart?.__typename === 'LasooCart'
        ? userCartResult.cart
        : undefined,
    )
  }

  return cartLevel === 'New_Cart_Primary'
    ? userCartResult?.success
    : internalCartResult?.success
}
