/* eslint-disable import/namespace */
import { type CartAddress } from '@/lib/generated/graphql'
import {
  type CartGroup,
  type CartLine,
  type InnerCartCustomerDetails,
  type UICart,
} from '../cart-types'
import * as Sentry from '@sentry/nextjs'
import { type CartLevels } from '@/lib/generated/hypertune'
import dayjs from 'dayjs'

const audit = 'cart-audit'

export const auditCartProperties = (
  cartLevel: CartLevels,
  shouldAuditCart: boolean,
  setShouldAuditCart: (auditCart: boolean) => void,
  cartInternalCreatedTime?: string | null,
  userCartCreatedTime?: string | null,
  oldCart?: UICart,
  newCart?: UICart,
  pagePath?: string,
): void => {
  if (
    !shouldAuditCart ||
    cartLevel !== 'Silent_Mode_With_Audit' ||
    oldCart == null ||
    newCart == null
  ) {
    return
  }

  if (cartInternalCreatedTime == null || userCartCreatedTime == null) {
    Sentry.captureEvent({
      message: `Cart created time problem ${cartInternalCreatedTime} ${userCartCreatedTime} ${dayjs(cartInternalCreatedTime).diff(userCartCreatedTime, 'minutes')}`,
    })
    setShouldAuditCart(false)
    return
  }

  if (
    Math.abs(
      dayjs(cartInternalCreatedTime).diff(userCartCreatedTime, 'minutes'),
    ) > 1
  ) {
    Sentry.captureEvent({
      message: `Cart created time mismatch ${cartInternalCreatedTime} ${userCartCreatedTime} ${dayjs(cartInternalCreatedTime).diff(userCartCreatedTime, 'minutes')}`,
    })
    setShouldAuditCart(false)
    return
  }

  try {
    const messages = auditCart(oldCart, newCart)
    if (messages.length > 0) {
      Sentry.captureException(
        new Error('old and new cart objects have different properties'),
        {
          extra: {
            messages,
            oldCart: JSON.stringify(oldCart),
            newCart: JSON.stringify(newCart),
          },
        },
      )
      setShouldAuditCart(false)
    } else {
      if (pagePath === '/cart/checkout') {
        Sentry.captureEvent(
          {
            level: 'info',
            message: 'old and new cart objects have same properties',
          },
          {
            data: {
              oldCart: JSON.stringify(oldCart),
              newCart: JSON.stringify(newCart),
            },
          },
        )
      } else {
        Sentry.addBreadcrumb({
          category: audit,
          level: 'info',
          message: 'old and new cart objects have same properties',
          data: {
            oldCart: JSON.stringify(oldCart),
            newCart: JSON.stringify(newCart),
          },
        })
      }
    }
  } catch (error) {
    Sentry.captureException(error)
    setShouldAuditCart(false)
  }
}

export const auditCart = (oldCart?: UICart, newCart?: UICart): string[] => {
  const messages: string[] = []

  auditValue(
    messages,
    'UICart.itemsValid',
    oldCart?.itemsValid,
    newCart?.itemsValid,
  )

  auditCartCustomerDetails(
    messages,
    'UICart.customerDetails',
    oldCart?.customerDetails,
    newCart?.customerDetails,
  )

  auditList(
    messages,
    'UICart.lineGroups',
    (item?: CartGroup, within?: CartGroup[]) =>
      within?.find((other) => other?.retailerName === item?.retailerName),
    auditCartGroup,
    (item?: CartGroup) => `${item?.retailerName ?? ''}`,
    oldCart?.lineGroups,
    newCart?.lineGroups,
  )

  auditValue(messages, 'UICart.total', oldCart?.total, newCart?.total)

  auditValue(
    messages,
    'UICart.shippingCost',
    oldCart?.shippingCost,
    newCart?.shippingCost,
  )

  auditValue(
    messages,
    'UICart.totalQuantity',
    oldCart?.totalQuantity,
    newCart?.totalQuantity,
  )

  auditValue(
    messages,
    'UICart.totalDiscount',
    oldCart?.totalDiscount,
    newCart?.totalDiscount,
  )

  auditValue(
    messages,
    'UICart.subtotalCart',
    oldCart?.subtotal,
    newCart?.subtotal,
  )

  auditValue(
    messages,
    'UICart.requireOverAgeCheck',
    oldCart?.requireOverAgeCheck,
    newCart?.requireOverAgeCheck,
  )

  auditValue(
    messages,
    'UICart.couponCode',
    oldCart?.couponCode,
    newCart?.couponCode,
  )

  auditValue(
    messages,
    'UICart.retailerDeliveryMethod',
    oldCart?.retailerDeliveryMethod,
    newCart?.retailerDeliveryMethod,
  )

  return messages
}

const auditCartGroup = (
  messages: string[],
  keyPrefix: string,
  oldCartGroup?: CartGroup | null,
  newCartGroup?: CartGroup | null,
) => {
  auditValue(
    messages,
    `${keyPrefix}.retailerName`,
    oldCartGroup?.retailerName,
    newCartGroup?.retailerName,
  )

  auditList(
    messages,
    `${keyPrefix}.lines`,
    (item?: CartLine, within?: CartLine[]) =>
      within?.find(
        (other) =>
          `${other?.advertId ?? ''}.${other?.variantId ?? ''}` ===
          `${item?.advertId ?? ''}.${other?.variantId ?? ''}`,
      ),
    auditCartLine,
    (item?: CartLine) => `${item?.advertId ?? ''}.${item?.variantId ?? ''}`,
    oldCartGroup?.lines,
    newCartGroup?.lines,
  )

  auditValue(
    messages,
    `${keyPrefix}.total`,
    oldCartGroup?.total,
    newCartGroup?.total,
  )

  auditValue(
    messages,
    `${keyPrefix}.retailerLogoSrc`,
    oldCartGroup?.retailerLogoSrc,
    newCartGroup?.retailerLogoSrc,
  )

  auditValue(
    messages,
    `${keyPrefix}.retailerLogoBgColor`,
    oldCartGroup?.retailerLogoBgColor,
    newCartGroup?.retailerLogoBgColor,
  )

  auditValue(
    messages,
    `${keyPrefix}.shippingEstimationMessage`,
    oldCartGroup?.shippingEstimationMessage,
    newCartGroup?.shippingEstimationMessage,
  )
}

const auditCartLine = (
  messages: string[],
  keyPrefix: string,
  oldCartLine?: CartLine,
  newCartLine?: CartLine,
) => {
  auditValue(
    messages,
    `${keyPrefix}.productId`,
    oldCartLine?.productId,
    newCartLine?.productId,
  )

  auditValue(
    messages,
    `${keyPrefix}.quantity`,
    oldCartLine?.quantity,
    newCartLine?.quantity,
  )

  auditValue(
    messages,
    `${keyPrefix}.price`,
    oldCartLine?.price?.toFixed(2).replace('.', ''),
    newCartLine?.price.toString(),
  )

  auditValue(
    messages,
    `${keyPrefix}.salePrice`,
    oldCartLine?.salePrice.toFixed(2).replace('.', ''),
    newCartLine?.salePrice.toString(),
  )

  auditValue(
    messages,
    `${keyPrefix}.productName`,
    oldCartLine?.productName,
    newCartLine?.productName,
  )

  auditValue(
    messages,
    `${keyPrefix}.productImageSrc`,
    oldCartLine?.productImageSrc,
    newCartLine?.productImageSrc,
  )

  auditValue(
    messages,
    `${keyPrefix}.variantId`,
    oldCartLine?.variantId,
    newCartLine?.variantId,
  )

  auditValue(
    messages,
    `${keyPrefix}.advertId`,
    oldCartLine?.advertId,
    newCartLine?.advertId,
  )

  auditValue(
    messages,
    `${keyPrefix}.maxQuantity`,
    oldCartLine?.maxQuantity,
    newCartLine?.maxQuantity,
  )

  auditValue(
    messages,
    `${keyPrefix}.available`,
    oldCartLine?.available,
    newCartLine?.available,
  )

  auditValue(
    messages,
    `${keyPrefix}.variantLabel`,
    oldCartLine?.variantLabel,
    newCartLine?.variantLabel,
  )

  auditValue(
    messages,
    `${keyPrefix}.status`,
    oldCartLine?.status,
    newCartLine?.status,
  )
}

const auditCartCustomerDetails = (
  messages: string[],
  keyPrefix: string,
  oldCartMetadata?: InnerCartCustomerDetails | null,
  newCartMetadata?: InnerCartCustomerDetails | null,
) => {
  if (
    auditObject(messages, keyPrefix, oldCartMetadata, newCartMetadata) ||
    oldCartMetadata == null
  ) {
    return
  }

  auditValue(
    messages,
    `${keyPrefix}.firstName`,
    oldCartMetadata?.firstName,
    newCartMetadata?.firstName,
  )

  auditValue(
    messages,
    `${keyPrefix}.surname`,
    oldCartMetadata?.surname,
    newCartMetadata?.surname,
  )

  auditValue(
    messages,
    `${keyPrefix}.emailAddress`,
    oldCartMetadata?.emailAddress,
    newCartMetadata?.emailAddress,
  )

  auditCartAddress(
    messages,
    `${keyPrefix}.address`,
    oldCartMetadata?.address,
    newCartMetadata?.address,
  )

  auditCartAddress(
    messages,
    `${keyPrefix}.billingAddress`,
    oldCartMetadata?.billingAddress,
    newCartMetadata?.billingAddress,
  )

  auditValue(
    messages,
    `${keyPrefix}.billingPhone`,
    oldCartMetadata?.billingPhone,
    newCartMetadata?.billingPhone,
  )
}

const auditCartAddress = (
  messages: string[],
  keyPrefix: string,
  oldAddress?: CartAddress | null,
  newAddress?: CartAddress | null,
) => {
  if (
    auditObject(messages, keyPrefix, oldAddress, newAddress) ||
    oldAddress == null
  ) {
    return
  }

  auditValue(
    messages,
    `${keyPrefix}.address`,
    oldAddress?.address,
    newAddress?.address,
  )
  auditValue(messages, `${keyPrefix}.city`, oldAddress?.city, newAddress?.city)
  auditValue(
    messages,
    `${keyPrefix}.country.code`,
    oldAddress?.country?.code,
    newAddress?.country?.code,
  )
  auditValue(
    messages,
    `${keyPrefix}.postcode`,
    oldAddress?.postcode,
    newAddress?.postcode,
  )
  auditValue(
    messages,
    `${keyPrefix}.state`,
    oldAddress?.state,
    newAddress?.state,
  )
}

const auditList = <T,>(
  messages: string[],
  key: string,
  find: (item: T | undefined, within: T[] | undefined) => T | undefined,
  audit: (messages: string[], key: string, oldValue: T, newValue: T) => void,
  keySuffix: (item?: T) => string,
  oldCartValue?: T[] | null,
  newCartValue?: T[] | null,
): void => {
  if (auditObject(messages, key, oldCartValue, newCartValue)) {
    return
  }

  if (oldCartValue == null || newCartValue == null) {
    return
  }

  if (oldCartValue.length !== newCartValue.length) {
    messages.push(
      `'${key}': LENGTH '${oldCartValue.length}' vs. '${newCartValue.length}'`,
    )
  }

  const usedKeys: string[] = []

  for (const oldCartItemValue of oldCartValue) {
    const newCartItemValue = find(oldCartItemValue, newCartValue)

    usedKeys.push(keySuffix(oldCartItemValue))

    if (
      auditObject(
        messages,
        `${key}[${keySuffix(oldCartItemValue)}]`,
        oldCartItemValue,
        newCartItemValue,
      ) ||
      oldCartItemValue == null
    ) {
      continue
    }

    audit(
      messages,
      `${key}[${keySuffix(oldCartItemValue)}]`,
      oldCartItemValue,
      newCartItemValue as T,
    )
  }

  for (const newCartItemValue of newCartValue) {
    if (usedKeys.includes(keySuffix(newCartItemValue))) {
      continue
    }

    auditObject(
      messages,
      `${key}[${keySuffix(newCartItemValue)}]`,
      undefined,
      newCartItemValue,
    )
  }
}

const auditObject = <T,>(
  messages: string[],
  key: string,
  oldCartValue?: T | null,
  newCartValue?: T | null,
): boolean => {
  if (oldCartValue != null && newCartValue == null) {
    messages.push(`'${key}': PROPERTY Does not exist on New Cart`)
    return true
  }
  if (newCartValue != null && oldCartValue == null) {
    messages.push(`'${key}': PROPERTY Does not exist on Old Cart`)
    return true
  }
  return false
}

const auditValue = <T,>(
  messages: string[],
  key: string,
  oldCartValue?: T | null,
  newCartValue?: T | null,
): boolean => {
  if (
    (oldCartValue == null && newCartValue == null) ||
    oldCartValue === newCartValue
  ) {
    return false
  }
  messages.push(`'${key}': VALUE '${oldCartValue}' vs. '${newCartValue}'`)
  return true
}
