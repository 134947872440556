import {
  type Cart,
  type CartAddress,
  type CartAddressInput,
  type RetailerDeliveryMethod,
} from '@/lib/generated/graphql'
import { deepEqual } from 'fast-equals'
import { type UserCart, type CartMetadata } from '../cart/cart-types'
import { type PaymentInfo } from './payment/payment-callbacks'
import { type LasooCartInput } from '@/lib/generated/platform-graphql'
import { mapUserCartToInput } from '../cart/cart-actions/map-platform-cart-to-input'

export interface CheckoutInfoAddress {
  countryCode: string
  streetAddress: string // or line 1
  suburb?: string // or line 2
  stateCode?: string // or province, county, etc.
  postcode?: string
}

// for form
export interface CheckoutInfo {
  firstName?: string
  lastName?: string
  email: string
  mobile?: string
  deliveryAddress?: CheckoutInfoAddress
  postcodeValid?: 'true' | 'false'
  sameAsAddress: 'same-as-delivery' | 'different-address'
  billingAddress?: CheckoutInfoAddress // if different
  recipientOver18Confirmed?: boolean
  customRecipient?: boolean
  recipientFirstName?: string
  recipientSurname?: string
  recipientMobile?: string
  requireOverAgeCheck?: boolean
  paymentInfo?: PaymentInfo
  recipientDateOfBirth?: Date | null
  retailerDeliveryMethod?: RetailerDeliveryMethod | null
  braintreeToken?: string | null
  postcodeInvalid?: boolean
}

export const mapFormToUserCart = (
  formValues: CheckoutInfo,
  requireOverAgeCheck: boolean = false,
  userCart: UserCart,
): LasooCartInput => {
  const inputCart = mapUserCartToInput(userCart)
  return {
    items: inputCart?.items ?? [],
    cartId: inputCart?.cartId,
    coupon: inputCart?.coupon,
    createdAt: inputCart?.createdAt,
    customerDetails: {
      firstName: formValues.firstName,
      surname: formValues.lastName,
      email: formValues.email,
      phone: formValues.mobile,
      recipientFirstName: requireOverAgeCheck
        ? formValues.recipientFirstName
        : formValues.firstName,
      recipientSurname: requireOverAgeCheck
        ? formValues.recipientSurname
        : formValues.lastName,
      recipientMobile: requireOverAgeCheck
        ? formValues.recipientMobile
        : formValues.mobile,
      recipientOver18Confirmed: requireOverAgeCheck,
      billingSameAsShippingAddress:
        // eslint-disable-next-line sonarjs/no-duplicate-string
        formValues.sameAsAddress === 'same-as-delivery',
      billingAddress: {
        ...inputCart?.customerDetails?.billingAddress,
        address: formValues.billingAddress?.streetAddress,
        city: formValues.billingAddress?.suburb,
        country: formValues.billingAddress?.countryCode ?? 'au',
        postcode: formValues.billingAddress?.postcode,
        state: formValues.billingAddress?.stateCode,
      },
      shippingAddress: {
        ...inputCart?.customerDetails?.shippingAddress,
        address: formValues.deliveryAddress?.streetAddress,
        city: formValues.deliveryAddress?.suburb,
        country: formValues.deliveryAddress?.countryCode ?? 'au',
        postcode: formValues.deliveryAddress?.postcode,
        state: formValues.deliveryAddress?.stateCode,
      },
    },
  }
}

export const toCartMetadata = (
  {
    firstName,
    lastName,
    email,
    mobile,
    deliveryAddress,
    sameAsAddress,
    billingAddress,
    recipientOver18Confirmed,
    customRecipient,
    recipientFirstName,
    recipientSurname,
    recipientMobile,
  }: CheckoutInfo,
  requireRecipient = false,
  cartId?: string | undefined,
): CartMetadata => {
  return {
    firstName: firstName ? firstName.trim() : undefined,
    billingFirstName: firstName ? firstName.trim() : undefined,
    surname: lastName ? lastName.trim() : undefined,
    billingSurname: lastName ? lastName.trim() : undefined,
    emailAddress: email,
    billingEmailAddress: email,
    phone: mobile ? mobile.trim() : undefined,
    billingPhone: mobile ? mobile.trim() : undefined,
    address: toCartAddressInput(deliveryAddress),
    billingAddress: toCartAddressInput(
      sameAsAddress === 'same-as-delivery' ? deliveryAddress : billingAddress,
    ),
    recipientFirstName:
      requireRecipient && customRecipient ? recipientFirstName : undefined,
    recipientSurname:
      requireRecipient && customRecipient ? recipientSurname : undefined,
    recipientMobile:
      requireRecipient && customRecipient ? recipientMobile : undefined,
    recipientOver18Confirmed: requireRecipient
      ? recipientOver18Confirmed
      : undefined,
    cartId,
  }
}

export const customerDetailsEquality = (
  cart: UserCart,
  formData: CheckoutInfo,
) => {
  if (cart === undefined || cart === null) return false
  const newCart = mapFormToUserCart(formData, false, cart)

  return deepEqual(newCart.customerDetails, cart.customerDetails)
}

export const cartMetaEquals = (cart: Cart, formData: CheckoutInfo) => {
  if (cart === undefined || cart === null) return false
  const cartMetadata = cartToMetaData(cart)
  const formMetadata = formToCartMetadata(formData, false, cart.id as string)
  return deepEqual(cartMetadata, formMetadata)
}

export const cartToMetaData = (cart: Cart): CartMetadata => ({
  firstName: cart.billingFirstName ? cart.billingFirstName.trim() : undefined,
  billingFirstName: cart.billingFirstName
    ? cart.billingFirstName.trim()
    : undefined,
  surname: cart.billingSurname ? cart.billingSurname.trim() : undefined,
  billingSurname: cart.billingSurname ? cart.billingSurname.trim() : undefined,
  emailAddress: cart.billingEmailAddress ?? undefined,
  billingEmailAddress: cart.billingEmailAddress ?? undefined,
  phone: cart.billingPhone ? cart.billingPhone.trim() : undefined,
  billingPhone: cart.billingPhone ? cart.billingPhone.trim() : undefined,
  address: cartToCartAddressInput(cart.address as CheckoutInfoAddress),
  billingAddress: cartToCartAddressInput(
    cart.billingAddress as CheckoutInfoAddress,
  ),
  recipientFirstName: undefined,
  recipientSurname: undefined,
  recipientMobile: undefined,
  recipientOver18Confirmed: undefined,
  cartId: cart.id as string,
})

export const cartToMetaDataCoupon = (
  cart: Cart,
  couponCode?: string | null,
): CartMetadata => ({
  ...cartToMetaData(cart),
  couponCode,
})

export const formToCartMetadata = (
  {
    firstName,
    lastName,
    email,
    mobile,
    deliveryAddress,
    sameAsAddress,
    billingAddress,
    recipientOver18Confirmed,
    customRecipient,
    recipientFirstName,
    recipientSurname,
    recipientMobile,
  }: CheckoutInfo,
  requireRecipient = false,
  cartId?: string | undefined,
): CartMetadata => ({
  firstName: firstName ? firstName.trim() : undefined,
  billingFirstName: firstName ? firstName.trim() : undefined,
  surname: lastName ? lastName.trim() : undefined,
  billingSurname: lastName ? lastName.trim() : undefined,
  emailAddress: email,
  billingEmailAddress: email,
  phone: mobile ? mobile.trim() : undefined,
  billingPhone: mobile ? mobile.trim() : undefined,
  address: toCartAddressInput(deliveryAddress),
  billingAddress: toCartAddressInput(
    sameAsAddress === 'same-as-delivery' ? deliveryAddress : billingAddress,
  ),
  recipientFirstName:
    requireRecipient && customRecipient ? recipientFirstName : undefined,
  recipientSurname:
    requireRecipient && customRecipient ? recipientSurname : undefined,
  recipientMobile:
    requireRecipient && customRecipient ? recipientMobile : undefined,
  recipientOver18Confirmed: requireRecipient
    ? recipientOver18Confirmed
    : undefined,
  cartId,
})

export const formToCartMetadataCoupon = (
  {
    firstName,
    lastName,
    email,
    mobile,
    deliveryAddress,
    sameAsAddress,
    billingAddress,
    recipientOver18Confirmed,
    customRecipient,
    recipientFirstName,
    recipientSurname,
    recipientMobile,
  }: CheckoutInfo,
  requireRecipient = false,
  couponCode?: string | null,
  cartId?: string | undefined,
): CartMetadata => ({
  ...formToCartMetadata(
    {
      firstName,
      lastName,
      email,
      mobile,
      deliveryAddress,
      sameAsAddress,
      billingAddress,
      recipientOver18Confirmed,
      customRecipient,
      recipientFirstName,
      recipientSurname,
      recipientMobile,
    },
    requireRecipient,
    cartId,
  ),
  couponCode,
})

export const toCartAddressInput = (
  address: CheckoutInfoAddress | undefined,
): CartAddressInput | undefined => {
  if (
    address === undefined ||
    address === null ||
    address.streetAddress === ''
  ) {
    return
  }
  const { streetAddress, suburb, countryCode, postcode, stateCode } = address
  return {
    address: streetAddress,
    city: suburb ?? 'N/A',
    countryCode,
    postcode: postcode ?? '[no postcode]',
    stateCode: stateCode ?? 'N/A',
  }
}

export const cartToCartAddressInput = (
  cartAddress: CartAddress | undefined,
): CartAddressInput | undefined => {
  if (
    cartAddress === undefined ||
    cartAddress === null ||
    cartAddress.address === ''
  ) {
    return
  }
  const { address, city, country, postcode, state } = cartAddress
  return {
    address: address as string,
    city: city ?? 'N/A',
    countryCode: country?.code as string,
    postcode: postcode ?? '[no postcode]',
    stateCode: state ?? 'N/A',
  }
}
