/* eslint-disable import/namespace */
import * as Sentry from '@sentry/nextjs'

import { type LasooCartInput } from '@/lib/generated/platform-graphql'

import { type Cart } from '@/lib/generated/graphql'
import { type CartLevels } from '@/lib/generated/hypertune'
import { toast } from 'react-toastify'
import { updateCartLine } from '../cart-actions/cart-actions'
import { type UserCart } from '../cart-types'
import { upsertCart } from '@/lib/data/cart/upsert'
import { mapUserCartToInput } from '../cart-actions/map-platform-cart-to-input'
import { deleteItemFromCartServer } from '@/lib/data/cart/remove-cart-item'

const updateNewCartQuantity = async (
  cartLevel: CartLevels,
  advertId: string,
  variantId: string,
  quantity: number,
  userCart?: UserCart,
) => {
  if (cartLevel === 'OFF') {
    return
  }

  try {
    const cartToUpdate: LasooCartInput = mapUserCartToInput(userCart)

    const existingItemIndex =
      cartToUpdate.items?.findIndex(
        (item) => item?.advertId === advertId && item?.variantId === variantId,
      ) ?? -1

    if (
      existingItemIndex > -1 &&
      cartToUpdate.items[existingItemIndex] != null
    ) {
      // what happens if quantity is <= 0?
      cartToUpdate.items[existingItemIndex].quantity = quantity
    }
    // do we still want to update this if there's no change?
    const cartResponse = await upsertCart({ cart: cartToUpdate })
    if (cartResponse == null || cartResponse === false) {
      Sentry.captureException(new Error('Error upserting new cart'))
      return
    }
    return cartResponse
  } catch (error) {
    Sentry.captureException(error)
    if (cartLevel === 'New_Cart_Primary') {
      throw error
    }
  }
}

const updateOldCartQuantity = async (
  productId: number,
  advertId: string,
  variantId: string,
  quantity: number,
  cartLevel: CartLevels,
  cartInternal?: Cart,
) => {
  try {
    const existingItemIndex =
      cartInternal?.items?.findIndex(
        (item) =>
          item?.advert?.id === advertId && item?.variant?.id === variantId,
      ) ?? -1

    if (existingItemIndex === -1) {
      throw new Error('Cannot modify quantity of line item that does not exist')
    }
    const lineId = cartInternal?.items?.[existingItemIndex]?.id as string
    if (quantity === 0) {
      const cart = await deleteItemFromCartServer({
        id: lineId,
        cartId: cartInternal?.id as string,
      })

      if (cart !== false && cart?.__typename === 'cart') {
        return cart
      }
    } else {
      const cart = await updateCartLine({
        advertId,
        id: lineId,
        productId,
        variantId,
        cartId: cartInternal?.id as string,
        clientMutationId: lineId,
        quantity,
      })
      if (cart !== false && cart?.__typename === 'cart') {
        return cart
      }
    }
  } catch (error) {
    Sentry.captureException(error)
    if (cartLevel !== 'New_Cart_Primary') {
      throw error
    }
  }
}

const showErrorToast = () => {
  toast('Error updating cart item', {
    className: 'bg-red-500 text-white',
    position: 'top-center',
  })
}

const showErrorDeleteToast = () => {
  toast('Error deleting cart item', {
    className: 'bg-red-500 text-white',
    position: 'top-center',
  })
}

export const updateCartItemQuantity = async (
  productId: number,
  advertId: string,
  variantId: string,
  quantity: number,
  setCart: (cart?: Cart, newCart?: UserCart) => void,
  cartLevel: CartLevels,
  cartInternal?: Cart,
  userCart?: UserCart,
) => {
  const newCartOperation =
    cartLevel === 'OFF'
      ? Promise.resolve(null)
      : updateNewCartQuantity(
          cartLevel,
          advertId,
          variantId,
          quantity,
          userCart,
        )

  const oldCartOperation =
    cartLevel === 'New_Cart_Primary'
      ? Promise.resolve(null)
      : updateOldCartQuantity(
          productId,
          advertId,
          variantId,
          quantity,
          cartLevel,
          cartInternal,
        )

  const [newCart, oldCart] = await Promise.all([
    newCartOperation,
    oldCartOperation,
  ])

  if (cartLevel === 'New_Cart_Primary') {
    if (newCart?.__typename === 'LasooCart') {
      setCart(undefined, newCart)
    } else {
      showErrorToast()
    }
  } else {
    if (oldCart?.__typename === 'cart') {
      setCart(
        oldCart as Cart,
        newCart?.__typename === 'LasooCart' ? newCart : undefined,
      )
    } else if (quantity === 0) {
      showErrorDeleteToast()
    } else {
      showErrorToast()
    }
  }
}
